import shopifyChromiumDefaults from '../shopify-chromium/config.js';

const wheelsFields = shopifyChromiumDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyChromiumDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const ignoreFields = [...wheelsFields, ...tiresFields, 'Universal', 'Fitment'];

export default {
  includes: ['shopify-chromium'],
  ...shopifyChromiumDefaults,
  searchPageUrl: '/pages/search',
  facets: {
    ...shopifyChromiumDefaults.facets,
    rangedFacet: [
      ...shopifyChromiumDefaults.facets.rangedFacet,
      { fields: ['wheel_weight'], name: 'sliderFacet', step: '1/10//1' },
    ],
  },
  fitmentSearch: {
    ...shopifyChromiumDefaults.fitmentSearch,
    isVehicleSelectionIsolated: true,
    categorySelectionPageRedirect: false,
    onVerifyFitmentInitResponseReceived: (response) => {
      const isWheelProduct = response.Items?.[0]?.category?.some((value) => value.toLowerCase() === 'wheels');
      const noFitmentData = response.Items?.[0]?.fitsTheVehicle === 'unknown';

      if (isWheelProduct && noFitmentData) {
        const verifyFitmentWidget = window.document.querySelector('#cm-verify-fitment');
        if (verifyFitmentWidget) {
          verifyFitmentWidget.style.display = 'none';
        }
      }
    },
  },
  Widgets: [
    ...shopifyChromiumDefaults.Widgets.filter(
      (w) =>
        ![
          'CategoryPage',
          'CategoryFacetPanel',
          'FacetBrandTiles',
          'DesktopGarage',
          'MobileGarage',
          'HomeVehicleWidget',
          'VerifyFitment',
          'FitmentTable',
          'FitmentTableTab',
          'SearchBoxDialogButton',
          'CategoryVehicleTitle',
          'SearchHeader',
          'FacetPanel',
        ].includes(w.name),
    ),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'collection_sort': 'Featured',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
        'published_at': 'Date, Old to New',
        'published_at:desc': 'Date, New to Old',
      },
      facetToggleConfig: {
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: shopifyChromiumDefaults.fitmentSearch.baseFields,
      },
    },
    {
      name: 'SubcategoryFacetTiles',
      type: 'FacetTiles',
      location: '#cm_subcategories',
      template: 'facetTiles/hierarchicalContainer',
      facetField: 'category',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.template-collection #main-layout',
      template: 'MainContent',
    },
    {
      name: 'CategoryFacetPanel',
      type: 'FacetPanel',
      location: { firstChildOf: 'body.template-collection #sidebarDrawer' },
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'FacetBrandTiles',
      type: 'FacetTiles',
      location: '#cm_brands-page',
      template: 'facetTiles/alphabeticalContainer',
      view: 'grid',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'DesktopGarage',
      type: 'Garage',
      location: {
        insertAfter: '.header-content .header-helpline',
        class: 'd-none d-lg-block cm_garage__desktop',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: {
        insertBefore: '.header-content .header-cart',
        wrapper: 'li',
        class: 'cm_garage__mobile',
      },
      template: 'fitmentSearch/garage',
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: { selector: '#cm_ymm-tab', class: 'cm_vehicle-widget__home-container' },
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 700,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: '#product_fitment_tab',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: '.product_fitment_tab',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '#mobile-bar .mobile-bar-content .mobile-bar_item.search',
        class: 'mobile-bar_item search cm_search-box-root__dialog_open-button-container',
      },
      template: 'searchBox/dialogButtonMobile',
    },
  ],
};
